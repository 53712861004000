import { ApplicationPage } from 'app/types';
import { Cont404 } from 'common/containers/404';

const Page404: ApplicationPage = () => <Cont404 />;

Page404.getMainLayoutProps = () => ({
  centered: true,
});

export default Page404;
